<template>
    <b-row>
      <b-col v-show="documentRepliesid" md="12">
        
          <b-card
            title="مسار الكتاب"
            class="text-left"
          >
            <b-card-text>
              يوضح هذه الجزء من الصفحة سير الكتاب بين الاقسام الادارية ذات الصلة
            </b-card-text>
            <app-timeline>
              <app-timeline-item 
              v-for="reply in replies" 
              :key="reply.id"
              icon="UserIcon">
                <div
                  class="
                    d-flex
                    flex-sm-row flex-column flex-wrap
                    justify-content-between
                    mb-1 mb-sm-0
                  "
                >
                
                  <h6>{{reply.user.full_name}}</h6>
                  <div>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="info"
                    class="btn-icon rounded-circle"
                      v-b-modal.model-r-w
                  >
                    <feather-icon icon="CornerDownRightIcon" />
                  </b-button>
                  
                  <small style="margin-right:10px;margin-left:10px;" class="text-muted">{{shortenDate(reply.created_at)}}</small></div>
                </div>
                <p>{{reply.message}} </p>
                <p v-if="reply.mentions.length>0">{{mentions(reply.mentions)}}</p>
                <p>
                <a v-if="reply.file!=null&&reply.file!='null'" :href="'https://www.nuc.edu.iq/newbackend/public/'+reply.file">
                  <b-img
                    :src="require('@/assets/images/icons/pdf.png')"
                    height="auto"
                    width="20"
                    class="mr-1"
                  />
                  <span class="align-bottom">الكتاب الرسمي</span></a>
                </p>
              </app-timeline-item>
            </app-timeline>
            <div class="demo-inline-spacing">
              <b-button
            @click="hideCard()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
            <feather-icon
            icon="Minimize2Icon"
            class="mr-50"
            />
              <span class="align-middle">اغلاق</span> 
            </b-button>
            <b-button
            @click="printProgress()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
            <feather-icon
            icon="ArchiveIcon"
            class="mr-50"
            />
              <span class="align-middle">طباعة</span> 
            </b-button>
            </div>
          </b-card>
        </b-col>
      
      <b-col md="12" class="table">
        <b-card-code title="طلبات الايميل" no-body>
          <b-card-body v-if="!$route.params.id">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- filter -->
              <b-form-group
                label="فلتر"
                label-cols-sm="1"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filterInput"
                    v-model="title"
                    type="search"
                    placeholder="ابحث عن .."
                  />
                  <b-input-group-append>
                    <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
                    <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="gS()"
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اعادة تحميل</span>
              </b-button>
                  </b-input-group-append>
                  
                </b-input-group>
                <b-form-checkbox-group
              id="checkbox-group-2"
              name="flavour-2"
              class="demo-inline-spacing"
              v-model="new_req"
            >
              <b-form-checkbox :value="true"> عرض طلبات قيد الانجاز </b-form-checkbox>
            </b-form-checkbox-group>

              </b-form-group>
            </div>
          </b-card-body>
          <b-row style="margin-right:10px">
                  <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.stage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="stages"
              placeholder="اختر المرحلة"
            />
          </b-form-group>
        </b-col>
                </b-row>
          <div class="demo-vertical-spacing">
            <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
          </div>
          <b-table
            ref="table"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :items="items"
            :fields="fields"
          >
            <template #cell(users)="data">
              <b-badge
                v-for="stage in data.item.users"
                :key="stage.id"
                pill
                :variant="stage.pivot.is_confirm"
              >
                {{ stage.full_name }}
              </b-badge>
            </template>
            <template #cell(Document)="file">
              <b-button
                v-if="file.item.image !== null"
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                variant="outline-info"
                @click="onClicked(file.item.image)"
              >
                عرض
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                variant="outline-danger"
                disabled
              >
                didn't uploaded
              </b-button>
            </template>
            <template #cell(action)="action">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-col md="3">
                  <b-button
                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                    variant="outline-success"
                    @click="pM(action.item.id)"
                  >
                    سير الكتاب
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-button
                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                    variant="outline-primary"
                    @click="fEM(action.item)"
                    v-b-modal.model-f-w
                  >
                    تحويل
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-button v-if="role"
                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                    variant="outline-success"
                    @click="finsh(action.item.id)"
                  >
                    تم
                  </b-button>
                </b-col>
              </b-dropdown>
            </template>
            <template #cell(status)="data">
            <b-badge v-if="data.item.is_done==1"
              pill
              :variant="success"
            >
              تم العمل
            </b-badge>
            <b-badge v-else
              pill
              :variant="waiting"
            >
              قيد العمل
            </b-badge>
          </template>
          <template #cell(email)="data">
              <a :href="'mailto:' + data.item.email"
                        >{{data.item.email}}</a
                      >
            </template>
          </b-table>
  
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                @change="check()"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
  
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="check()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
          <b-modal
            id="model-e-w"
            ok-title="حفظ التعديلات"
            centered
            size="lg"
            title="تعديل كتاب رسمي"
            @ok="uM"
            @hidden="rEF"
          >
            <b-form novalidate class="needs-validation">
              <b-form-row>
                <b-col md="12">
                  <b-form-group>
                    <label for="basic-password">المرسل لهم</label>
                    <v-select
                      v-model="editform.users"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="full_name"
                      multiple
                      :options="users"
                      placeholder="اختر المنتسبين"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="basic-password">نوع الكتاب</label>
                    <v-select
                      v-model="editform.type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="ar_title"
                      :options="types"
                      placeholder="اختر نوع الكتاب"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="basic-password"
                      >النوع الفرعي</label
                    >
                    <v-select
                      v-model="editform.subtype_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="ar_title"
                      :options="subtypes"
                      placeholder="اختر النوع الفرعي للكتاب(ان وجد)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label for="input-valid1">الموضوع :</label>
                  <b-form-input
                    id="input-valid1"
                    v-model="editform.title"
                    :state="editform.title.length > 0"
                    placeholder="الموضوع"
                  />
                </b-col>
  
                <b-col md="6">
                  <label for="input-invalid1">العدد:</label>
                  <b-form-input
                    id="input-invalid1"
                    v-model="editform.number"
                    placeholder="العدد"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-group label="تاريخ الكتاب" label-for="date">
                    <cleave
                      id="date"
                      v-model="editform.date"
                      class="form-control"
                      :raw="false"
                      :options="date"
                      placeholder="YYYY-MM-DD"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label for="input-invalid1">تفاصبل الكتاب:</label>
                  <b-form-textarea
                    id="input-invalid1"
                    placeholder="تفاصبل الكتاب"
                    v-model="editform.description"
                    rows="3"
                  />
                </b-col>
                <b-col md="12">
                  <label for="input-valid1">اختر ملف الكتاب (pdf.):</label>
                  <b-form-file
                  placeholder="اختر الصورة او اسقطها هنا..."
                  drop-placeholder="اختر الصورة او اسقطها هنا..."
                    v-model="editform.file"
                    @change="selectFileForForm"
                  />
                </b-col>
              </b-form-row>
            </b-form>
          </b-modal>
          <b-modal
            id="model-r-w"
            ok-title="حفظ البيانات"
            centered
            size="lg"
            title="الرد على الكتاب"
            @ok="rDM"
            @hidden="rRM"
          >
            <b-form novalidate class="needs-validation">
              <b-form-row>
                <b-col md="12">
                  <label for="input-invalid1">الرسالة او التوجيه:</label>
                  <b-form-textarea
                    id="input-invalid1"
                    placeholder="الرسالة او التوجيه"
                    v-model="replyform.message"
                    rows="3"
                  />
                </b-col>
                <b-col md="12">
                  <label for="input-valid1">اختر ملف الكتاب (pdf.):</label>
                  <b-form-file
                  placeholder="اختر الصورة او اسقطها هنا..."
                  drop-placeholder="اختر الصورة او اسقطها هنا..."
                    v-model="replyform.file"
                    @change="selectFileForForm"
                  />
                </b-col>
              </b-form-row>
            </b-form>
          </b-modal>
          <b-modal
            id="model-f-w"
            ok-title="حفظ البيانات"
            centered
            size="lg"
            title="تحويل طلب"
            @ok="fM"
            @hidden="rF"
          >
            <b-form novalidate class="needs-validation">
              <b-form-row>
                <b-col md="12">
                  <b-form-group>
                    <label for="basic-password">موجه الى</label>
                    <v-select
                      v-model="forwardform.users"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="full_name"
                      multiple
                      :options="users"
                      placeholder="اختر المنتسبين"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label for="input-invalid1">الرسالة او التوجيه:</label>
                  <b-form-textarea
                    id="input-invalid1"
                    placeholder="الرسالة او التوجيه"
                    v-model="forwardform.message"
                    rows="3"
                  />
                </b-col>
              </b-form-row>
            </b-form>
          </b-modal>
          <b-modal
            id="modal-center"
            centered
            title="حذف الكتاب"
            @ok="dM"
            ok-title="حذف الحقل"
          >
            <b-card-text>
              هل تريد حذف هذه الحقل "
              {{ this.editform.ar_name }} " ?
            </b-card-text>
          </b-modal>
        </b-card-code>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import { filesUrl } from "@/main.js";
  import store from "@/store";
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
  import {
    BTable,
    BAvatar,
    BModal,BCard,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BForm,
    BFormRow,
    BCol,
    BProgress,
    VBModal,
    BBadge,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BRow,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardBody,
    BIconFileEarmarkSlides,
    BImg,
    BMedia,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    VBTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import { SID, TID, AID } from "@/main.js";
  import Cleave from "vue-cleave-component";
  import "cleave.js/dist/addons/cleave-phone.us";
  import emailjs from "@emailjs/browser";
  import { rmdir } from "fs";
  export default {
    components: {
      BCardCode,
      BTable,
      BForm,BCard,
      BCardActions,
      vSelect,
      BAvatar,
      BProgress,
      BFormFile,
      Cleave,
      BBadge,
      AppTimeline,
      AppTimelineItem,
      BRow,
      BFormRow,
      BDropdown,
      BDropdownItem,
      BFormGroup,
      togglePasswordVisibility,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormCheckboxGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BModal,
      VBModal,
      Ripple,
      BCol,
      BFormCheckbox,
      BCardText,
      BFormValidFeedback,
      BFormTextarea,
      BFormInvalidFeedback,
      BImg,
      BMedia,
      BCollapse,
      VBToggle,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      VBTooltip,
    },
    data() {
      return {
        precentage: 0,
        teacher_id: "",departments:[],
        stages:[],
        perPage: 5,
        new_req:false,
        pageOptions: [3, 5, 10, 100],
        totalRows: 1,
        role: null,
        canEdit: null,
        canDelete: null,success: 'success',
      waiting: 'warning',
        currentPage: 0,
        documentRepliesid: false,
        documentreplyID:null,
        department: "",
        title: "",
        replies: [],
        iD: "",
        item: "",
        id: "",
        filter: null,
        filterOn: [],
        departments: [],
        teachers: [],
        users: [],
        search: {
        department_title:"",
        department_id:null,
        stage_title:"",
        stage_id:null,
          types: [],
          subtypes: [],
          type_id: "",
          subtype_id: "",
          fromdate: "",
          todate: "",
        },
        fields: [
          {
            key: "title",
            label: "اسم الطالب",
          },
          {
            key: "depart",
            label: "القسم",
          },
          {
            key: "stage",
            label: "المرحلة الدراسية",
          },
          {
            key: "email",
            label: "الايميل",
          },
          {
            key: "Document",
            label: "المرفق",
          },
          { key: "status", label: "حالة الطلب" },
          { key: "action", label: "الاجراءات" },
        ],
        exportFields: {
          Title: "ar_name",
          Stage: "stage.ar_name",
          Syllabus: "celbas",
          Department: "department.ar_name",
          Quntity: "files_count",
          Lecturer: "teacher.ar_name",
        },
        exportData: [
          {
            TotalName: "full_name",
            Stage: "stage.ar_name",
            Syllabus: "celbas",
            Department: "department.ar_name",
            Quntity: "files_count",
            Lecturer: "teacher.ar_name",
          },
        ],
        items: [],
        notifi: {
          route: "",
          title: "",
          item_id: "",
          table: "",
          users: [],
          departments: [],
          user_id: "",
          teacher_id: "",
          role_id: "",
          type: "",
          subtitle: "",
          bg: "",
        },
        form: {
          title: "",
          depart: "",
          stage: "",
          email: "",
          file: "",
        },
        editform: {
          title: "",
          depart: "",
          stage: "",
          email: "",
          file: "",
          id: null,
        },
        forwardform: {
          message: "",
          users: [],
          email_id: null,
        },
        replyform: {
          message: null,
          file: null,
          users:[],
          email_id: null,
        },
        date: {
          date: true,
          delimiter: "-",
          datePattern: ["Y", "m", "d"],
        },
        documentMenitions:[],
        toEmail: false,
        toName: false,
        subject: null,
        toID: null,
        m: null,
        toUserID: null,
        types: [],
        subtypes: [],
      };
    },
    watch: {
      title: function (val) {
        this.gS();
      },
      "$route.params.id": {
        handler: function (search) {
          this.gS();
        },
      },
      "$route.params.document_id": {
        handler: function (search) {
          this.gS();
        },
      },
      "search.type_id": function (val) {
        if (val != null || val != "") {
          this.gS();
        }
      },
      "search.fromdate": function (val) {
        if (val != null || val != "") {
          this.gS();
        }
      },
    "search.stage_id": function (val) {
      if (val != null || val != "") {
        this.gS();
      }
    },
    "search.department_id": function (val) {
      if (val != null || val != "") {
        this.gM2()
        this.gS()
      }
    },
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
    },
    directives: {
      Ripple,
    },
    mounted() {
      if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "registration") {
        this.role = true;
      } else {
        this.role = false;
      }
      if (
        localStorage.getItem("NisourRole") == "admin" ||
        localStorage.getItem("NisourRole") == "coordinator"
      ) {
        this.canEdit = true;
        if (localStorage.getItem("NisourRole") == "coordinator") {
          this.iD = localStorage.getItem("NisourDepartmentId");
        }
      } else {
        this.canEdit = false;
      }
  
      if (localStorage.getItem("NisourRole") == "admin") {
        this.canDelete = true;
      } else {
        this.canDelete = false;
        this.search.department = localStorage.getItem("NisourDepartmentId");
      }
      this.ID(), this.gS(), this.gU(), this.gTD(), this.gSTD(),this.gM1();
    },
    methods: {
      async gM1(){
      await this.axios
        .get(`since/deparment?take=100&skip=${
            this.currentPage - 1
          }&select=true&type=5`)
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gM2(){
      this.stages = []
      this.search.stage_id==""
      console.log(this.search.department_id)
      if(! Number.isInteger(this.search.department_id)){
        this.search.department_title = this.search.department_id["title"]
        this.search.department_id = this.search.department_id["id"]
      }
      await this.axios
        .get(`stage/clint/department/${this.search.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
      async gTD() {
        await this.axios
          .get(`types?hr=true`)
          .then((res) => {
            this.types = [];
            this.types = res.data.items;
            this.search.types = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async gSTD() {
        await this.axios
          .get(`subtypes`)
          .then((res) => {
            this.subtypes = [];
            this.subtypes = res.data.items;
            this.search.subtypes = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
  
      async gU() {
        await this.axios
          .get(`users-name`)
          .then((res) => {
            this.users = [];
            this.users = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async uM() {
        let data = new FormData();
        data.append("title", this.editform.title);
        data.append("depart", this.editform.depart);
        data.append("stage", this.editform.stage);
        data.append("email", this.editform.email);
        data.append("file", this.editform.file);
       
        await this.axios
          .post(`emails/${this.editform.id}`, data, {
            onUploadProgress: (progressEvent) => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            },
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              if (this.editform.users.length > 0) {
                for (let i = 0; i < this.editform.users.length; i++) {
                  (this.toEmail = this.editform.users[i].email),
                    (this.toName = this.editform.users[i].full_name),
                    (this.toID = this.editform.users[i].teacher_id),
                    (this.toUserID = this.editform.users[i].id),
                    (this.subject = this.editform.title),
                    (this.m = `سيد ${
                      this.toName
                    } : نود اعلامك بورود تحقق من ${localStorage.getItem(
                      "NisourUserName"
                    )}لغرض عمل حساب جامعي. يرجى فتح الحساب و تأكيد الاستلام `);
                  this.sendEmail(this.m, this.toName, this.toEmail);
                  this.notifi.route = "em-notif";
                  this.notifi.title = "طلب تحقق وارد ";
                  this.notifi.item_id = res.data.id;
                  this.notifi.table = "email_reqs";
                  this.notifi.user_id = this.toUserID;
                  this.notifi.type = "light-success";
                  this.notifi.subtitle = ` ورد اليك طلب تحقق `;
                  this.notifi.bg = "bg-success";
                  this.sendNoti();
                }
              }
  
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
              this.precentage = 0;
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async fM(){
        let id = this.forwardform.document_id
        const ids = [];
        if (this.forwardform.users.length > 0) {
          this.forwardform.users.map(function (value, key) {
            ids.push(value.id);
          });
        }
        let data = new FormData();
        data.append("email_id", this.forwardform.email_id);
        data.append("message", this.forwardform.message);
        ids.forEach((element) => data.append("users[]", element));
        await this.axios
          .post(`email/forward`, data, {
            onUploadProgress: (progressEvent) => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            },
          })
          .then((res) => {
            alert(this.forwardform.users.length)
            this.pM(id)
            if (res.data.errors) {
              this.errorToast();
            } else {
              if (this.forwardform.users.length > 0) {
                
                for (let i = 0; i < this.forwardform.users.length; i++) {
                  (this.toEmail = this.forwardform.users[i].email),
                    (this.toName = this.forwardform.users[i].full_name),
                    (this.toID = this.forwardform.users[i].teacher_id),
                    (this.toUserID = this.forwardform.users[i].id),
                    (this.subject = this.forwardform.users.message),
                    (this.m = `سيد ${
                      this.toName
                    } : نود اعلامك بانه قدر تم تحويل كتاب رسمي اليك من ${localStorage.getItem(
                      "NisourUserName"
                    )} يرجى فتح الحساب والاطلاع `);
                  this.sendEmail(this.m, this.toName, this.toEmail);
                  this.notifi.route = "em-notif";
                  this.notifi.title = "طلب محول ";
                  this.notifi.item_id = this.forwardform.email_id;
                  this.notifi.table = "emails";
                  this.notifi.user_id = this.toUserID;
                  this.notifi.type = "light-success";
                  this.notifi.subtitle = `طلب محول اليك`;
                  this.notifi.bg = "bg-success";
                  this.sendNoti();
                }
              }
  
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
              this.precentage = 0;
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
          this.rFM()
      },
      
      rFM(){
        this.message ="",
        this.users = []
      },
      async rDM(){
        let id = this.replyform.document_id
        let data = new FormData();
        data.append("message", this.replyform.message);
        data.append("email_id", this.replyform.email_id);
        data.append("file", this.replyform.file);
        await this.axios
          .post(`email/reply`, data, {
            onUploadProgress: (progressEvent) => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            },
          })
          .then((res) => {
            this.pM(res.data.email_reqs_id)
            if (res.data.errors) {
              this.errorToast();
            } else {
              if (this.replyform.users.length > 0) {
                
                for (let i = 0; i < this.replyform.users.length; i++) {
                  (this.toEmail = this.replyform.users[i].email),
                    (this.toName = this.replyform.users[i].full_name),
                    (this.toID = this.replyform.users[i].teacher_id),
                    (this.toUserID = this.replyform.users[i].id),
                    (this.subject = this.replyform.message),
                    (this.m = `سيد ${
                      this.toName
                    } : نود اعلامك بانه قدر تم الردعلى الكتاب الرسمي من ${localStorage.getItem(
                      "NisourUserName"
                    )} يرجى فتح الحساب والاطلاع `);
                  this.sendEmail(this.m, this.toName, this.toEmail);
                  this.notifi.route = "em-notif";
                  this.notifi.title = "الردود ";
                  this.notifi.item_id = this.replyform.email_id;
                  this.notifi.table = "emails";
                  this.notifi.user_id = this.toUserID;
                  this.notifi.type = "light-success";
                  this.notifi.subtitle = ` ورد اليك رد جديد `;
                  this.notifi.bg = "bg-success";
                  this.sendNoti();
                }
              }
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
              this.precentage = 0;
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rRM()
      },
      rRM(){
        this.replyform.message = null,
        this.replyform.file = null,
        this.documentMenitions = []
      },
      sendEmail(m, n, e) {
        const fromName = localStorage.getItem("NisourUserName");
        const fromEmail = localStorage.getItem("NisourEmail");
        emailjs
          .send(
            SID,
            TID,
            {
              from_name: fromName,
              to_name: n,
              message: m,
              to_email: e,
              reply_to: fromEmail,
            },
            AID
          )
          .then(
            (result) => {
              this.makeToast(
                "success",
                "Notification Sender",
                `The notification has been sent successfully`
              );
              this.toName = "";
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
      },
      async aNM() {
        if (isNaN(this.form.type_id)) {
          this.form.type_id = this.form.type_id["id"];
        }
        if (isNaN(this.form.subtype_id)) {
          this.form.subtype_id = this.form.subtype_id["id"];
        }
        const ids = [];
        if (this.form.users.length > 0) {
          this.form.users.map(function (value, key) {
            ids.push(value.id);
          });
        }
        let data = new FormData();
        data.append("title", this.form.title);
        data.append("number", this.form.number);
        data.append("type_id", this.form.type_id);
        data.append("date", this.form.date);
        data.append("subtype_id", this.form.subtype_id);
        data.append("description", this.form.description);
        data.append("file", this.form.file);
        ids.forEach((element) => data.append("users[]", element));
        await this.axios
          .post(`documents`, data, {
            onUploadProgress: (progressEvent) => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            },
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              if (this.form.users.length > 0) {
                for (let i = 0; i < this.form.users.length; i++) {
                  (this.toEmail = this.form.users[i].email),
                    (this.toName = this.form.users[i].full_name),
                    (this.toID = this.form.users[i].teacher_id),
                    (this.toUserID = this.form.users[i].id),
                    (this.subject = this.form.title),
                    (this.m = `سيد ${this.toName} : نود اعلامك بورود كتاب رسمي من ${this.fromName} يرجى فتح الحساب و تأكيد الاستلام `);
                  this.sendEmail(this.m, this.toName, this.toEmail);
                  this.notifi.route = "documents-notif";
                  this.notifi.title = "كتاب رسمي وارد ";
                  this.notifi.item_id = res.data.id;
                  this.notifi.table = "documents";
                  this.notifi.user_id = this.toUserID;
                  this.notifi.type = "light-success";
                  this.notifi.subtitle = `ورد اليك كتاب رسمي من قبل ${this.fromName}`;
                  this.notifi.bg = "bg-success";
                  this.sendNoti();
                }
              }
              this.gS();
            }
            this.rF();
            this.precentage = 0;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
      async sendNoti() {
        await this.axios.post(`notifications`, this.notifi);
      },
      rF() {
        (this.form.title = ""),
          (this.form.numbe = ""),
          (this.form.date = ""),
          (this.form.type_id = ""),
          (this.form.subtype_id = ""),
          (this.form.description = ""),
          (this.documentMenitions = []),
          (this.form.users = []),
          (this.form.file = "");
      },
      rEF() {
        (this.editform.title = ""),
          (this.editform.numbe = ""),
          (this.editform.date = ""),
          (this.editform.type_id = ""),
          (this.editform.subtype_id = ""),
          (this.editform.description = ""),
          (this.documentMenitions = []),
          (this.editform.users = []),
          (this.editform.file = "");
      },
      async dM() {
        await this.axios
          .delete(`documents/${this.editform.id}`)
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "Delete Success",
                "Your item is deleted successfully"
              );
              this.notifi.route = "lectures-notif";
              this.notifi.title = "delete action";
              this.notifi.item_id = res.data.data.id;
              this.notifi.table = "lectures";
              this.notifi.role_id = 1;
              this.notifi.type = "light-danger";
              this.notifi.subtitle = "deleted lecture";
              this.notifi.bg = "bg-danger";
              this.sendNoti();
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      eRM(item) {
        (this.editform.title = item.title),
          (this.editform.number = item.number),
          (this.editform.date = item.date);
        this.editform.type_id = item.type_id;
        this.editform.subtype_id = item.subtype_id;
        this.editform.description = item.description;
        this.editform.file = item.file;
        this.editform.users = item.users;
        this.editform.id = item.id;
      },
      dRM(item) {
        this.editform.name = item.name;
        this.editform.id = item.id;
      },
      check() {
        setTimeout(() => this.gS(), 1000);
      },
      ID() {
        this.iD = localStorage.getItem("NisourUserId");
      },
      async gS() {
        let title=""
        if(this.search.stage_id){
        title = this.search.stage_id["title"]
      }
        await this.axios
          .get(
            `emails?take=${this.perPage}&skip=${this.currentPage - 1}&new_req=${this.new_req}&stage_id=${title}
            &title=${this.title}&request_id=${this.$route.params.id}&bydepartment_id=${this.search.department_title}
            `
          )
          .then((res) => {
            this.items = [];
            this.totalRows = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      selectFileForForm(e) {
        this.form.file = "";
        this.form.file = e.target.files[0];
      },
      selectFileForEditForm(e) {
        this.editform.file = "";
        this.editform.file = e.target.files[0];
      },
      makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
      resetSearch() {
        (this.title = ""),
          (this.search.department_id = null),
          (this.search.stage_id = null),
          this.search.department_title=null,
          this.search.stage_title = null,
          (this.search.fromdate = ""),
          (this.search.todate = "");
        this.gS();
      },
      onClicked(path) {
        window.open(`${filesUrl}/${path}`, "_blank");
      },
      async cM(item) {
        await this.axios
          .get(`documents/${item.id}`)
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "Delete Success",
                "Your item is deleted successfully"
              );
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async finsh(id){
        await this.axios
          .get(`email/finsh/${id}`)
          .then((res) => {
            this.gS()
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rRM()
      },
      async pM(item) {
        this.replyform.email_id = item
        await this.axios
          .get(`emails/replies?email_id=${item}`)
          .then((res) => {
            this.replies = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
          this.documentRepliesid =false
        this.replyform.email_id =item;
        this.documentRepliesid = true
      },
      shortenDate(date) {
        let newDate = date.split("");
        newDate = newDate.slice(0, 10);
        newDate = newDate.join("");
        return newDate;
      },
      mentions(mentions){
        let items =[]
        mentions.forEach((element) => 
        items +=`${element.full_name} , `)
        return items;
      },
      fEM(item){
        this.forwardform.email_id = item.id;
        this.forwardform.users = item.users
        this.documentMenitions = item.users
        if(this.documentMenitions){
        this.documentMenitions[this.documentMenitions.length]= item.user
        }
      },
      rR(item){
        
        this.replyform.document_id = item.id
        this.replyform.users = item.users
        if(this.replyform.users){
        this.replyform.users[this.replyform.users.length]= item.user
        }
      },
      hideCard(){
        this.documentRepliesid = false;
      },
    },
    setup() {
      const printProgress = () => {
        window.print()
      }
  
      return {
        printProgress,
      }
    }
  };
  </script>
  <style lang="scss">
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .table{
      display: none !important;
    }
    .demo-inline-spacing{
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  </style>
  